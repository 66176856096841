<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import $ from "jquery"
export default {
  name: "HomeView",

  mounted(){
   if(!$('.http-found')){
     this.$router.push('/PageNotFound')
   }
},



};
</script>
<style lang="scss">
#app {
  font-family: "montserrat";
  -webkit-font-smoothing: "montserrat";
  -moz-osx-font-smoothing: "montserrat";
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: "montserrat";
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
