<template>
  <div class="">
    <AlumniHeader />
    <AlumniBannerty />
    <!-- <scholarShipView /> -->
    <electivView />
    <onlineDegree />
    <referReward />
    <AlumniView />
    <FooterView />
  </div>
</template>

<script>
import AlumniHeader from "@/components/Alumni/AlumniHeader.vue";
import AlumniBannerty from "@/components/Alumni/AlumniBannerty.vue";
// import scholarShipView from "@/components/Alumni/scholarshipView.vue";
import electivView from "@/components/Alumni/electivView.vue";
import onlineDegree from "@/components/Alumni/onlineDegree.vue";
import referReward from "@/components/Alumni/referReward.vue";
import AlumniView from "@/components/Alumni/AlumniView.vue";
import FooterView from "@/components/Alumni/footer.vue";
import { useHead } from "@unhead/vue";
export default {
  name: "AlumniLP",
  components: {
    AlumniHeader,
    AlumniBannerty,
    // scholarShipView,
    electivView,
    onlineDegree,
    referReward,
    AlumniView,
    FooterView,
  },
  created() {
    useHead({
      script: [
        {
          innerHTML: `gtag('event', 'conversion', {'send_to': 'AW-413149258/X8diCMKa74oYEMrQgMUB'});`,
          type: 'text/javascript',
        },
      ],
    });
  },
};
</script>

<style scoped></style>
