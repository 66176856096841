<template>
  <div class="bg-[#b60739] m-auto justify-center">
    <!--mob version-->
    <div class="flex flex-wrap flex-col justify-center items-center m-auto lg:hidden ">
      <div class="flex m-auto mob-banner justify-center items-center" >
        <div class="p-[20%]">
      <div class="items-center m-auto justify-center pt-4 ">
        <div
      class="bg-white py-3 w-[100%] justify-center m-auto text-left text-[15px] align-middle lg:mt-16 self-center p-5"
    >
      <p class="text-lg"  >
Congratulations, you have successfully unlocked an exclusive 20% alumni scholarship which can be availed on any of our online degree programs.
</p>
<p class="text-xl pt-4"> To avail the scholarship, use code: ALUMNI20
      </p>
    </div>
      </div>
    </div>
      </div>
    </div>
    <!--mob version-->
    <!--desktopVersion-->
    <div class="hidden lg:block m-auto relative justify-center max-w-7xl">
      <div
        class="banner h-[38rem] xl:h-[35rem] flex justify-between"
      >
        <div class="mt-8 pl-[60%] float-right ">
          <div
      class="bg-white py-3 xl:py-8 w-[100%] p-5 md:mr-[30px] text-left text-[15px] align-middle lg:mt-16 self-center p-5 mt-[-15%]"
    >
      <p class="text-lg"  >
Congratulations, you have successfully unlocked an exclusive 20% alumni scholarship which can be availed on any of our online degree programs.
</p>
<p class="text-xl pt-4"> To avail the scholarship, use code: ALUMNI20
      </p>
    </div>
        </div>
      </div>
    </div>
    <!--desktopVersion-->
  </div>
</template>

<script>
import { useHead } from "@unhead/vue";
// import EnquireAlumni from "@/components/LPEnquire/EnquireAlumni.vue";
export default {
  components: {
    // EnquireAlumni,
  },
  mounted() {
useHead({
      script: [
        {
          innerHTML: `gtag('event', 'conversion', {'send_to': 'AW-413149258/X8diCMKa74oYEMrQgMUB'});`,
          type: 'text/javascript',
        },
      ],
    });
  },
};
</script>

<style scoped>
.banner {
  background-image: url("@/assets/alumni-lp/desktop-banner-2.gif"); /* Path to your image */
  background-size: cover;
  background-position: center;
  width: 100%;
}

.mob-banner {
  background-image: url("@/assets/alumni-lp/Mobile-banner.gif"); /* Path to your image */
  background-size: cover;
  background-position: center;
  padding-bottom: 10px;
  padding-top: 100%;
  width: 100%;

}
</style>
