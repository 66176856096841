<template>
  <div class="bg-[#b60739] m-auto justify-center">
    <!--mob version-->
    <div class="flex flex-wrap flex-col justify-center items-center m-auto lg:hidden ">
      <div class="flex m-auto mob-banner justify-center items-center" >
        <div class="pb-[10%]">
      <div class="items-center m-auto justify-center pt-4 ">
        <EnquireAlumni />
      </div>
    </div>
      </div>
    </div>
    <!--mob version-->
    <!--desktopVersion-->
    <div class="hidden lg:block m-auto relative justify-center max-w-7xl">
      <div
        class="banner h-[38rem] xl:h-[35rem] flex justify-between"
      >
        <div class="mt-8 pl-[60%]">
          <EnquireAlumni />
        </div>
      </div>
    </div>
    <!--desktopVersion-->
  </div>
</template>

<script>
import EnquireAlumni from "@/components/LPEnquire/EnquireAlumni.vue";
export default {
  components: {
    EnquireAlumni,
  },
};
</script>

<style scoped>
.banner {
  background-image: url("@/assets/alumni-lp/desktop-banner-2.gif"); /* Path to your image */
  background-size: cover;
  background-position: center;
  width: 100%;
}

.mob-banner {
  background-image: url("@/assets/alumni-lp/Mobile-banner.gif"); /* Path to your image */
  background-size: cover;
  background-position: center;
  padding-bottom: 10px;
  padding-top: 100%;
  width: 100%;

}
</style>
