<template>
  <div class="">
    <AlumniHeader />
    <AlumniBanner />
    <!-- <scholarShipView /> -->
    <electivView />
    <onlineDegree />
    <referReward />
    <AlumniView />
    <FooterView />
  </div>
</template>

<script>
import AlumniHeader from "@/components/Alumni/AlumniHeader.vue";
import AlumniBanner from "@/components/Alumni/AlumniBanner.vue";
// import scholarShipView from "@/components/Alumni/scholarshipView.vue";
import electivView from "@/components/Alumni/electivView.vue";
import onlineDegree from "@/components/Alumni/onlineDegree.vue";
import referReward from "@/components/Alumni/referReward.vue";
import AlumniView from "@/components/Alumni/AlumniView.vue";
import FooterView from "@/components/Alumni/footer.vue";
export default {
  name: "AlumniLP",
  components: {
    AlumniHeader,
    AlumniBanner,
    // scholarShipView,
    electivView,
    onlineDegree,
    referReward,
    AlumniView,
    FooterView,
  },
};
</script>

<style scoped></style>
