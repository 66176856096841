<template>
  <div class="bg-white max-w-7xl m-auto">
    <div class="md:w-[20rem] w-[13rem] m-auto md:ml-16 2xl:ml-0">
      <img
        src="@/assets/vignan-logo.svg"
        alt="logo"
        class="py-2 md:ml-4"
      />
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue";
export default {
  name: "aluminaiHeader",
  mounted() {
    useHead({
      meta: [{ name: "robots", content: "noindex" }],
    });
  },
};
</script>

<style scoped></style>
